import React from 'react';
import styled from 'styled-components';
import { PlayIcon } from '../../assets/icons';
import { canAutoplay, isIos } from '../../lib/features';
import { nextIndex, previousIndex } from '../../lib/indices';
import { VRTMediaPlayer } from '../../services/VRTMediaPlayerService/IVRTMediaPlayerService';
import { Spinner } from '../Spinner/Spinner';
import { VrtPlayer } from '../VrtPlayer/VrtPlayer';
import { TVrtPlayerConfig } from '../VrtPlayer/types';
import { TStoryContextValue } from './StoryContext';
import { getNetworkSpeed, getSaveDataSetting } from '../../lib/network';
import { StoryProgressBar } from './StoryProgressBar';
import { TStoryOverlayType } from './types';
import { getStoryGradientBackground } from './getStoryGradientBackground';
import { Text } from '../Text/Text';

export interface TStoryVideoPlayerProps {
  context: TStoryContextValue;
  data: TStoryVideoData;
}

export interface TStoryVideoData {
  id: string;
  index: number;
  duration: number;
  vrtPlayerConfig: TVrtPlayerConfig;
  overlayType: TStoryOverlayType;
  digitalData: {} | null;
}

export interface TStoryVideoPlayerState {
  isPaused: boolean;
  player: VRTMediaPlayer | null;
  shouldMountVideo: boolean;
  hasError: boolean;
}

export class StoryVideoPlayer extends React.Component<TStoryVideoPlayerProps, TStoryVideoPlayerState> {
  constructor(props: TStoryVideoPlayerProps) {
    super(props);
    this.state = {
      isPaused: this.isActive && canAutoplay,
      player: null,
      shouldMountVideo: false,
      hasError: false,
    };
  }

  get isActive() {
    const { activeStoryIndex } = this.props.context;
    const { index } = this.props.data;
    return activeStoryIndex === index;
  }

  get isNext() {
    const { activeStoryIndex, total } = this.props.context;
    const { index } = this.props.data;
    return nextIndex(activeStoryIndex, total) === index;
  }

  get isPrevious() {
    const { activeStoryIndex, total } = this.props.context;
    const { index } = this.props.data;
    return previousIndex(activeStoryIndex, total) === index;
  }

  get showBottomGradient() {
    return this.props.data.overlayType === 'reactions';
  }

  handleVideoPlayerOnInitialize = (player: VRTMediaPlayer) => {
    this.setState({ player });
    // alternative to chromeless player
    player.disableInterface();
    // pause the video when it ends, as it does not loop
    player.addEventListener('ended', () => this.setState({ isPaused: true }));
    // set initial play/pause state, try autoplay when possible
    if (this.isActive && canAutoplay) {
      player.play();
      this.setState({ isPaused: false });
    } else {
      player.pause();
      this.setState({ isPaused: true });
    }
  };

  handleVideoPlayerOnError = () => {
    this.setState({ hasError: true });
  };

  togglePlay = () => {
    if (this.state.player) {
      this.setState({ isPaused: !this.state.isPaused });
      this.state.player.togglePlay();
    }
  };

  computeShouldMountPlayer(): boolean {
    // the current player should always be mounted
    if (this.isActive) {
      return true;
    }
    // mounting multiple players on ios is not a good idea
    if (isIos) {
      return false;
    }
    // if the network is known to be slow
    if (getNetworkSpeed() === 'slow') {
      return false;
    }
    // if the useragent indicates to prefer saving data
    if (getSaveDataSetting() === 'yes') {
      return false;
    }
    // only keep next/prev mounted to keep at most 3 players mounted at any time
    return this.isNext || this.isPrevious;
  }

  componentDidMount() {
    // only mount story video elements that are strictly needed to reduce memory cost
    this.setState({ shouldMountVideo: this.computeShouldMountPlayer() });
  }

  componentDidUpdate(prevProps: TStoryVideoPlayerProps, prevState: TStoryVideoPlayerState) {
    // track changes to the videos that should be mounted whenever the selected video changes
    if (prevProps.context.activeStoryIndex !== this.props.context.activeStoryIndex) {
      const shouldMountVideo = this.computeShouldMountPlayer();
      if (this.state.shouldMountVideo !== shouldMountVideo) {
        this.setState({ shouldMountVideo });
      }
    }

    const { player } = this.state;
    if (!player) {
      return;
    }

    if (prevProps.context.activeStoryIndex !== this.props.context.activeStoryIndex) {
      if (!this.isActive) {
        player.pause();
        this.setState({ isPaused: true });
      } else {
        player.currentTime = 0;
        if (canAutoplay) {
          player.play();
          this.setState({ isPaused: false });
        }
      }
    } else if (this.isActive) {
      if (player.duration > 0) {
        player.play();
        this.setState({ isPaused: false });
      } else {
        const play = () => {
          player?.play();
          this.setState({ isPaused: false });
          player?.config?.playerRootElement?.removeEventListener('canplay', play);
        };
        player?.config?.playerRootElement?.addEventListener('canplay', play);
      }
    }
  }

  render() {
    const {
      isActive,
      props: { data },
    } = this;
    const { vrtPlayerConfig, duration, digitalData } = data;
    const { shouldMountVideo, player, isPaused, hasError } = this.state;
    return (
      <StyledWrap>
        {shouldMountVideo && (
          <StyledPlayerContainer isShown={!!(player && isActive)}>
            {hasError ? (
              <StyledError>Deze video is momenteel niet beschikbaar</StyledError>
            ) : (
              <VrtPlayer
                config={{ ...vrtPlayerConfig, abrStrategy: 'quality' }}
                digitalData={digitalData}
                uiConfig={{ disableControls: true }}
                onInitialized={this.handleVideoPlayerOnInitialize}
                onError={this.handleVideoPlayerOnError}
              />
            )}
          </StyledPlayerContainer>
        )}
        {isActive && (
          <StyledOverlay onClick={this.togglePlay} showBottomGradient={this.showBottomGradient}>
            {player ? <>{isPaused ? <PlayIcon /> : <StoryProgressBar durationInMs={duration} />}</> : <Spinner />}
          </StyledOverlay>
        )}
      </StyledWrap>
    );
  }
}

const StyledOverlay = styled.div<{ showBottomGradient: boolean }>`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all 0.2s ease-in;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 20;
  ${({ showBottomGradient }) => getStoryGradientBackground(showBottomGradient)}
`;

const StyledWrap = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
`;

const StyledPlayerContainer = styled.div<{ isShown: boolean }>`
  display: ${({ isShown }) => (isShown ? 'block' : 'none')};
  width: 100%;
  height: 100%;
`;

const StyledError = styled(Text)`
  ${({ theme }) => `
  display: block-inline;
  background-color: ${theme.colors.red};
`}
`;
